import React, { useEffect, useState, useCallback, useMemo } from "react";
import { User, Clock, Phone, Users, ChevronDown, ChevronUp, Filter } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";

import { RootState, AppDispatch } from "../../redux/store";
import ToasterGen from "../../helper/Toaster";
import { callsData } from "../../redux/dataStore/callsSlice";
import { DEFAULT_COOKIE_GETTER } from "../../helper/Cookie";
import getApi from "../../helper/getApi";

// Types
interface TimerState {
  elapsedTime: number;
  status: string;
}

interface AgentDetails {
  device_aor: string;
  huntgroup_name: string;
  entry_status: string;
  session_count: number;
  last_update: string;
}

interface TabButtonProps {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
  icon?: React.ReactNode;
}

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

interface WebSocketData {
  type: string;
  userId?: string;
  event?: any;
  elapsedTime?: number;
  status?: string;
  stopped?: boolean;
  userData?: {
    first_name?: string;
    last_name?: string;
  };
  username?: string;
  data?: AgentDetails;
}

// Component Definitions
const TabButton = ({ active, onClick, children, icon }: any) => (
  <button
    onClick={onClick}
    className={`px-3 py-2 text-sm font-semibold rounded-t-lg transition-all duration-200 flex items-center gap-1 ${
      active
        ? "bg-white text-green-600 border-t-2 border-l border-r border-green-600 shadow-sm"
        : "text-gray-600 hover:text-gray-800 hover:bg-gray-50"
    }`}
  >
    {icon}
    <span className="hidden sm:inline">{children}</span>
  </button>
);

const Card = ({ children, className = "" }: any) => (
  <div className={`bg-white rounded-lg shadow-lg ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children }: any) => (
  <div className="px-4 py-4 border-b border-gray-100">{children}</div>
);

const CardContent = ({ children }: any) => (
  <div className="p-4">{children}</div>
);

// Table Components
const Table = ({ children, className = "" }: any) => (
  <div className="overflow-x-auto">
    <table className={`table-auto divide-y divide-gray-200 ${className}`}>
      {children}
    </table>
  </div>
);

const TableHead = ({ children }: any) => (
  <thead className="bg-gray-50">{children}</thead>
);

const TableBody = ({ children }: any) => (
  <tbody className="bg-white divide-y divide-gray-200">{children}</tbody>
);

const TableHeader = ({
  children,
  sortable = false,
  sorted = null,
  onSort = () => {}
}: any) => (
  <th
    scope="col"
    className={`px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
      sortable ? "cursor-pointer" : ""
    }`}
    onClick={sortable ? onSort : undefined}
  >
    <div className="flex items-center gap-1">
      {children}
      {sortable && (
        <span className="inline-flex flex-col">
          {sorted === "asc" ? (
            <ChevronUp className="w-3 h-3" />
          ) : sorted === "desc" ? (
            <ChevronDown className="w-3 h-3" />
          ) : null}
        </span>
      )}
    </div>
  </th>
);

const TableCell = ({ children, className = "" }: any) => (
  <td className={`px-3 py-3 ${className}`}>{children}</td>
);

// Status badges
const StatusBadge = ({ status, color }: any) => (
  <span
    className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${color} text-white`}
  >
    {status}
  </span>
);

// Helper: Normalize userId
const getNormalizedUserId = (userId: string) =>
  userId.includes("@") ? userId.split("@")[0] : userId;

// Main component
const Main = () => {
  // States
  const [userTimers, setUserTimers] = useState<{ [key: string]: TimerState }>(
    {}
  );
  const [staticUserIds, setStaticUserIds] = useState<string[]>([]);

  const [agents, setAgents] = useState<any>({});
  const [agentList, setAgentList] = useState<AgentDetails[]>([]);
  const [userList, setUserList] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectTeams, setSelectTeams] = useState<any[]>([]);
  const [managers, setManagers] = useState<string[]>([]);
  const [selectedManager, setSelectedManager] = useState<string>("");
  const [managerTeam, setManagerTeam] = useState<string[]>([]);
  const [userEvents, setUserEvents] = useState<{ [key: string]: any[] }>({});
  const [selectedUserForModal, setSelectedUserForModal] =
    useState<string | null>(null);
  const [selectedLiveUsers, setSelectedLiveUsers] = useState<string[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string>("all-team");
  const [activeTab, setActiveTab] = useState("live-status");
  const [isLoading, setIsLoading] = useState(true);
  const [columnPage, setColumnPage] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const { calls, loading, error } = useSelector(
    (state: RootState) => state.calls
  );

  const columns = [
    "call-term-user",
    "call-term-domain",
    "call-term-uri",
    "call-start-datetime",
    "call-answer-datetime"
  ];

  useEffect(() => {
    // Fetch calls immediately on mount
    dispatch(callsData({}));

    // Set an interval to fetch data every 10 seconds
    const interval = setInterval(() => {
      dispatch(callsData({}));
    }, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [dispatch]);

  // Sorting states
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({ key: "", direction: "asc" });

  const [agentSortConfig, setAgentSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({ key: "", direction: "asc" });

  // Get user info
  const userData = useMemo(
    () => JSON.parse(localStorage.getItem("user_data") || "{}"),
    []
  );
  const isAdmin = userData.role === "Admin";

  // Connect to WebSocket
  useEffect(() => {
    const socket = new WebSocket("wss://webhook.atomip.uk/ws");

    socket.onopen = () => console.log("WebSocket connected");

    socket.onmessage = (event) => {
      try {
        const data: any = JSON.parse(event.data);

        if (data.extraData && data.userId) {
          setUserEvents((prev: any) => ({
            ...prev,
            [data.userId]: [data.extraData]
          }));
        }

        if (
          data.type === "callTimer" ||
          data.type === "idleTimer" ||
          data.type === "timer"
        ) {
          handleTimerUpdate(data);
        } else if (data.type === "agent" && data.data) {
          handleAgentUpdate(data);
        }
      } catch (error) {
        console.error("Error parsing WebSocket data:", error);
      }
    };

    socket.onclose = () => console.log("WebSocket disconnected");
    return () => socket.close();
  }, []);

  // Fetch team data
  useEffect(() => {
    async function fetchUsers() {
      setIsLoading(true);
      try {
        const activationToken = await DEFAULT_COOKIE_GETTER("access_token");
        const headers = {
          Authorization: "Bearer " + activationToken,
          Accept: "*/*",
          "Access-Control-Allow-Origin": true
        };

        const dataEmail = await DEFAULT_COOKIE_GETTER("user");
        const { email, role } = JSON.parse(dataEmail || "{}");

        const response: any = await getApi(
          "auth/get_team_by_email",
          encodeURIComponent(JSON.stringify({ email, role })),
          headers
        );

        setUserList(response.data);
        setSelectTeams(response.teams);
        setManagers(response.teams.map((team: object) => Object.keys(team)[0]));
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchUsers();
  }, []);

  // Handler functions
  const handleTimerUpdate = useCallback((data: any) => {
    if (data.userId) {
      // Add the userId to staticUserIds only if not already present.
      setStaticUserIds((prev) =>
        prev.includes(data.userId) ? prev : [...prev, data.userId]
      );
    }

    if (data.stopped === true && data.userId) {
      setUserTimers((prev) => {
        const updated = { ...prev };
        delete updated[data.userId];
        return updated;
      });
      setAgents((prev: any) => {
        const updated = { ...prev };
        delete updated[data.userId];
        return updated;
      });
    } else if (data.userId && data.elapsedTime !== undefined && data.status) {
      setUserTimers((prev) => ({
        ...prev,
        [data.userId]: { elapsedTime: data.elapsedTime, status: data.status }
      }));

      if (data.userData?.first_name && data.userData?.last_name) {
        const fullName = `${data.userData.first_name} ${data.userData.last_name}`;
        setAgents((prev: any) => ({
          ...prev,
          [data.userId]: fullName
        }));
      } else if (data.username) {
        setAgents((prev: any) => ({
          ...prev,
          [data.userId]: data.username
        }));
      }
    }
  }, []);

  const handleAgentUpdate = useCallback((data: WebSocketData) => {
    if (!data.data) return;

    setAgentList((prev) => {
      const updatedList = [...prev];
      const existingIndex = updatedList.findIndex(
        (agent) => agent.device_aor === data.data!.device_aor
      );

      if (existingIndex !== -1) {
        updatedList[existingIndex] = data.data!;
      } else {
        updatedList.push(data.data!);
      }

      return updatedList;
    });
  }, []);

  const handleManagerChange = useCallback(
    (manager: string) => {
      const team = selectTeams.find((team) => Object.keys(team).includes(manager));
      if (team) {
        const userValues = team[manager].map((user: any) => String(user));
        setManagerTeam(userValues);
      } else {
        setManagerTeam([]);
      }
      setSelectedManager(manager);
    },
    [selectTeams]
  );

  const handleUserChange = useCallback((user: string) => {
    setSelectedUsers((prev) =>
      prev.includes(user) ? prev.filter((u) => u !== user) : [...prev, user]
    );
  }, []);

  const handleLiveUserChange = useCallback((userId: string) => {
    setSelectedLiveUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  }, []);

  // Sorting functions
  const requestSort = (key: string) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const requestAgentSort = (key: string) => {
    let direction: "asc" | "desc" = "asc";
    if (agentSortConfig.key === key && agentSortConfig.direction === "asc") {
      direction = "desc";
    }
    setAgentSortConfig({ key, direction });
  };

  // Utility functions
  const formatElapsedTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((totalSeconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (totalSeconds % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const getStatusColor = useCallback((status: string, timer: string) => {
    if (status === "open" || status === "idle") {
      try {
        const [hours, minutes, seconds] = timer.split(":").map(Number);
        const idleInSeconds = hours * 3600 + minutes * 60 + seconds;

        if (idleInSeconds >= 20 * 60) return "bg-red-500";
        if (idleInSeconds >= 10 * 60) return "bg-yellow-400";
        return "bg-blue-400";
      } catch (e) {
        return "bg-green-500";
      }
    }

    switch (status) {
      case "inactive":
        return "bg-gray-300";
      case "active":
        return "bg-green-500";
      case "open":
        return "bg-blue-500";
      case "in call":
        return "bg-green-500";
      case "call ended":
        return "bg-red-500";
      default:
        return "bg-gray-300";
    }
  }, []);

  // Computed values
  const teamUserIds = useMemo(() => {
    if (!selectedTeam || selectedTeam === "all-team") return [];
    const team = selectTeams.find(
      (team) => Object.keys(team)[0] === selectedTeam
    );
    if (!team) return [];
    return team[selectedTeam].map((id: any) =>
      String(id).trim().toLowerCase()
    );
  }, [selectedTeam, selectTeams]);

  // When "all-team" is selected, select all users.
  useEffect(() => {
    if (selectedTeam === "all-team") {
      setSelectedLiveUsers(staticUserIds);
    } else if (selectedTeam) {
      const newSelected = staticUserIds.filter((userId) =>
        teamUserIds.includes(getNormalizedUserId(userId))
      );
      setSelectedLiveUsers(newSelected);
    } else {
      setSelectedLiveUsers([]);
    }
  }, [selectedTeam, staticUserIds, teamUserIds]);

  const filteredLiveUsers = useMemo(() => {
    const users = Object.keys(userTimers)
      .filter((userId) =>
        selectedLiveUsers.length > 0 ? selectedLiveUsers.includes(userId) : true
      )
      .filter((userId) => {
        // If "all-team" is selected or no team is selected, return all users.
        if (selectedTeam === "all-team" || !selectedTeam) {
          return true;
        }
        return teamUserIds.includes(getNormalizedUserId(userId));
      });

    if (sortConfig.key) {
      return [...users].sort((a, b) => {
        let aValue, bValue;

        switch (sortConfig.key) {
          case "name":
            aValue = agents[a] || a;
            bValue = agents[b] || b;
            break;
          case "status":
            aValue = userTimers[a].status;
            bValue = userTimers[b].status;
            break;
          case "time":
            aValue = userTimers[a].elapsedTime;
            bValue = userTimers[b].elapsedTime;
            break;
          default:
            aValue = a;
            bValue = b;
        }

        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    return users;
  }, [userTimers, selectedLiveUsers, selectedTeam, teamUserIds, agents, sortConfig]);

  // Render
  return (
    <div className="min-h-screen bg-gray-50">
      <Card className="mx-auto lg:max-w-3xl px-4 sm:px-6 lg:px-8 py-6">
        <CardHeader>
          <div className="flex items-center gap-3">
            <User className="w-7 h-7 sm:w-8 sm:h-8 text-green-600" />
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                User Live Dashboard
              </h1>
              <p className="text-gray-500 mt-1">Real Time Activity</p>
            </div>
          </div>
        </CardHeader>
      </Card>

      {/* Tab Navigation */}
      <div className="flex gap-2 mb-4 bg-gray-100 p-2 rounded-lg max-w-7xl mx-auto overflow-x-auto">
        <TabButton
          active={activeTab === "live-status"}
          onClick={() => setActiveTab("live-status")}
          icon={<Clock className="w-5 h-5" />}
        >
          Live Status
        </TabButton>
      </div>

      {/* Loading State */}
      {isLoading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></div>
        </div>
      ) : (
        <>
          {/* Live Status Tab */}
          {activeTab === "live-status" && (
            <Card className="max-w-7xl mx-auto px-2 sm:px-4">
              <CardContent>
                {/* Team Filter */}
                <div className="mb-6">
                  <label className="block text-sm font-semibold text-gray-700 mb-2">
                    Select Team:
                  </label>
                  <select
                    value={selectedTeam}
                    onChange={(e) => setSelectedTeam(e.target.value)}
                    className="w-full p-3 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all"
                  >
                    <option value="all-team">All Teams</option>
                    {managers.map((manager, index) => (
                      <option key={index} value={manager}>
                        {manager}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Checkboxes for users */}
                {staticUserIds.length > 0 && (
                  <div className="mb-6 p-4 bg-gray-50 rounded-lg">
                    <p className="font-bold text-lg mb-3 text-gray-700">
                      Select Users to Group:
                    </p>
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
                      {staticUserIds
                        .filter((userId) =>
                          selectedTeam && selectedTeam !== "all-team"
                            ? teamUserIds.includes(getNormalizedUserId(userId))
                            : true
                        )
                        .map((userId) => (
                          <label
                            key={userId}
                            className="flex items-center space-x-2 px-3 py-1 bg-white rounded-full shadow-sm hover:shadow-md transition-all"
                          >
                            <input
                              type="checkbox"
                              checked={selectedLiveUsers.includes(userId)}
                              onChange={() => handleLiveUserChange(userId)}
                              className="rounded border-gray-300 text-green-500 focus:ring-green-500"
                            />
                            <span className="text-sm font-medium">
                              {agents[userId] || userId}
                            </span>
                          </label>
                        ))}
                    </div>
                  </div>
                )}

                {/* User Status Table */}
                <div className="mb-12 overflow-x-auto">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="font-bold text-xl text-gray-800">
                      Live User Status
                    </h3>
                    <div className="flex items-center text-sm text-gray-500">
                      <Filter className="w-4 h-4 mr-1" />
                      <span>{filteredLiveUsers.length} users</span>
                    </div>
                  </div>

                  {filteredLiveUsers.length > 0 ? (
                    <Card className="overflow-x-auto">
                      <div className="mb-12 overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          {/* Table Header (hidden on small screens) */}
                          <thead className="hidden sm:table-header-group">
                            <tr>
                              <th
                                className="px-4 py-2 text-left text-xs sm:text-sm font-medium text-gray-500 cursor-pointer"
                                onClick={() => requestSort("name")}
                              >
                                User
                              </th>
                              <th
                                className="px-4 py-2 text-left text-xs sm:text-sm font-medium text-gray-500 cursor-pointer"
                                onClick={() => requestSort("status")}
                              >
                                Status
                              </th>
                              <th
                                className="px-4 py-2 text-left text-xs sm:text-sm font-medium text-gray-500 cursor-pointer"
                                onClick={() => requestSort("time")}
                              >
                                Elapsed Time
                              </th>
                              <th className="px-4 py-2 text-left text-xs sm:text-sm font-medium text-gray-500">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          {/* Table Body */}
                          <tbody className="bg-white divide-y divide-gray-200">
                            {filteredLiveUsers.map((userId) => {
                              const timerData = userTimers[userId];
                              const formattedTime = formatElapsedTime(timerData.elapsedTime);
                              const statusColor = getStatusColor(timerData.status, formattedTime);
                              return (
                                <tr key={userId} className="block sm:table-row">
                                  {/* User Cell */}
                                  <td className="px-4 py-2 block sm:table-cell" data-label="User">
                                    <div className="flex items-center">
                                      <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center bg-green-100 rounded-full">
                                        <User className="h-5 w-5 text-green-500" />
                                      </div>
                                      <div className="ml-4">
                                        <div className="text-sm font-medium text-gray-900">
                                          {agents[userId] || "Unknown User"}
                                        </div>
                                        <div className="text-xs text-gray-500">{userId}</div>
                                      </div>
                                    </div>
                                  </td>
                                  {/* Status Cell */}
                                  <td className="px-4 py-2 block sm:table-cell" data-label="Status">
                                    <StatusBadge
                                      status={
                                        timerData.status === "in call"
                                          ? "IN USE"
                                          : timerData.status.toUpperCase()
                                      }
                                      color={statusColor}
                                    />
                                  </td>
                                  {/* Elapsed Time Cell */}
                                  <td className="px-4 py-2 block sm:table-cell" data-label="Elapsed Time">
                                    <div className="flex items-center">
                                      <Clock className="mr-2 h-4 w-4 text-gray-400" />
                                      <span className="text-sm font-medium">
                                        {formattedTime}
                                      </span>
                                    </div>
                                  </td>
                                  {/* Actions Cell */}
                                  <td className="px-4 py-2 block sm:table-cell" data-label="Actions">
                                    <button
                                      onClick={() => setSelectedUserForModal(userId)}
                                      className="text-green-600 hover:text-green-800 font-medium text-sm"
                                    >
                                      Details
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Card>
                  ) : (
                    <p className="text-gray-500 italic text-center py-8 bg-white rounded-lg shadow">
                      No users available with the current filters.
                    </p>
                  )}
                </div>
              </CardContent>
            </Card>
          )}
        </>
      )}

      {/* User Modal */}
      {selectedUserForModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg max-h-[90vh] overflow-y-auto w-11/12 md:w-1/2 lg:w-1/3">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">
                {agents[selectedUserForModal] || selectedUserForModal}
              </h2>
              <span className="text-sm text-gray-500">
                ID: {selectedUserForModal}
              </span>
            </div>

            <div className="border-t border-b border-gray-200 py-4 my-4">
              {userEvents[selectedUserForModal] &&
              Array.isArray(userEvents[selectedUserForModal]) &&
              userEvents[selectedUserForModal].length > 0 ? (
                (() => {
                  const firstEvent =
                    userEvents[selectedUserForModal][0];
                  const allKeys = Object.keys(firstEvent);
                  const totalPages = Math.ceil(allKeys.length / 4);
                  const keysToShow = allKeys.slice(
                    columnPage * 4,
                    columnPage * 4 + 4
                  );
                  return (
                    <>
                      {userEvents[selectedUserForModal].map(
                        (event, idx) => (
                          <div
                            key={idx}
                            className="mb-6 border p-4 rounded"
                          >
                            <h3 className="text-lg font-semibold mb-2">
                              Event
                            </h3>
                            <table className="min-w-full">
                              <tbody>
                                {keysToShow.map((key) => (
                                  <tr key={key}>
                                    <td className="px-4 py-2 font-semibold text-gray-500 uppercase">
                                      {key}
                                    </td>
                                    <td className="px-4 py-2 text-gray-700">
                                      {event[key]}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )
                      )}
                      <div className="flex justify-between mt-4">
                        <button
                          onClick={() =>
                            setColumnPage((prev) => Math.max(prev - 1, 0))
                          }
                          disabled={columnPage === 0}
                          className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
                        >
                          Previous
                        </button>
                        <button
                          onClick={() =>
                            setColumnPage((prev) =>
                              Math.min(prev + 1, totalPages - 1)
                            )
                          }
                          disabled={columnPage === totalPages - 1}
                          className="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50"
                        >
                          Next
                        </button>
                      </div>
                    </>
                  );
                })()
              ) : (
                <p className="text-gray-600">
                  No events available for this user.
                </p>
              )}
            </div>

            <div className="flex justify-end gap-2">
              <button
                onClick={() => setSelectedUserForModal(null)}
                className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-100 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mb-12 overflow-x-auto p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-bold text-xl text-gray-800">
            Inbound Call Data
          </h3>
        </div>

        {loading && <p>Loading...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
        {!loading && !error && (
          <Card className="overflow-x-auto shadow-lg rounded-lg">
            <div className="p-4">
              <table className="min-w-full bg-white">
                <thead>
                  <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    {columns.map((col) => (
                      <th key={col} className="py-3 px-6 text-left">
                        {col}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {calls.length > 0 ? (
                    calls.map((call, index) => (
                      <tr
                        key={index}
                        className="border-b border-gray-200 hover:bg-gray-100 transition-colors duration-200"
                      >
                        {columns.map((col) => {
                          let cellData = call[col];
                          if (
                            col === "call-start-datetime" ||
                            col === "call-answer-datetime"
                          ) {
                            if (cellData === "0000-00-00T00:00:00+00:00") {
                              cellData = "Not Answered";
                            } else {
                              try {
                                cellData = new Date(cellData).toLocaleString();
                              } catch (err) {
                                // Keep the original value if conversion fails
                              }
                            }
                          }
                          return (
                            <td
                              key={col}
                              className="py-3 px-6 text-left whitespace-nowrap font-semibold"
                            >
                              {cellData}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={columns.length} className="text-center p-6">
                        No calls available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Card>
        )}
      </div>

      <ToasterGen />
    </div>
  );
};

export default Main;
