import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import { summaryData } from '../../redux/dataStore/dataSlice';
import { DEFAULT_COOKIE_GETTER } from '../../helper/Cookie';
import getApi from '../../helper/getApi';
import postApi from '../../helper/postApi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Search, ArrowUpDown, Calendar, Users, Phone, Clock } from 'lucide-react';

// Custom Card Components
const Card = ({ children, className = "" }: { children: React.ReactNode; className?: string }) => (
  <div className={`bg-white rounded-lg shadow-md ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children }: { children: React.ReactNode }) => (
  // Add responsive padding
  <div className="px-4 sm:px-6 py-4 sm:py-5 border-b border-gray-200">
    {children}
  </div>
);

const CardContent = ({ children }: { children: React.ReactNode }) => (
  // Add responsive padding
  <div className="p-4 sm:p-6">
    {children}
  </div>
);

const SummaryDetail = () => {
  const dispatch = useDispatch<AppDispatch>();
  const data: any = useSelector((state: RootState) => state.summary);

  // State management
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [userList, setUserList] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Handle date range submission
  const handleDateSubmit = async () => {
    try {
      setIsSubmitting(true);
      const activationToken = await DEFAULT_COOKIE_GETTER('access_token');
      const headers = {
        'Authorization': 'Bearer ' + activationToken,
        'Accept': '*/*',
        'Access-Control-Allow-Origin': true,
      };

      let dataEmail = await DEFAULT_COOKIE_GETTER('user');
      dataEmail = JSON.parse(dataEmail || '{}').email;

      const finalResult = await postApi('data/setFilter', {
        start_date: startDate,
        end_date: endDate,
        email: dataEmail
      }, headers);

      dispatch(
        summaryData({
          searchQuery,
          sortColumn,
          sortDirection,
          dataEmail,
          startDate,
          endDate
        })
      );
      setFilteredData(finalResult?.data?.datafinal);
    } catch (error) {
      console.error('Error submitting date range:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle sorting
  const handleSort = (column: string) => {
    setSortDirection(sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc');
    setSortColumn(column);
  };

  // Fetch users
  useEffect(() => {
    async function fetchUsers() {
      setIsLoading(true);
      try {
        const activationToken = await DEFAULT_COOKIE_GETTER('access_token');
        const headers = {
          'Authorization': 'Bearer ' + activationToken,
          'Accept': '*/*',
          'Access-Control-Allow-Origin': true,
        };

        let dataEmail = await DEFAULT_COOKIE_GETTER('user');
        const email = JSON.parse(dataEmail || '{}').email;
        const role = JSON.parse(dataEmail || '{}').role;

        const response: any = await getApi(
          'auth/get_team_by_email',
          encodeURIComponent(JSON.stringify({ email, role })),
          headers
        );
        setUserList(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchUsers();
  }, []);

  // Sort and filter data whenever sortColumn or sortDirection changes
  useEffect(() => {
    if (!sortColumn) return;

    const sortedData = [...filteredData].sort((a: any, b: any) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      if (aValue == null) return 1;
      if (bValue == null) return -1;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return sortDirection === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    });

    setFilteredData(sortedData);
  }, [sortColumn, sortDirection]);

  const columns = [
    { key: 'userId', label: 'User ID' },
    { key: 'agentName', label: 'Agent Name' },
    { key: 'totalCalls', label: 'Total Calls' },
    { key: 'totalTalkTime', label: 'Total Talk Time' },
    { key: 'incomingTotal', label: 'Incoming Total' },
    { key: 'incomingMissed', label: 'Incoming Missed' },
    { key: 'incomingAvgPerHour', label: 'Incoming Avg/Hour' },
    { key: 'incomingTalkTime', label: 'Incoming Talk Time' },
    { key: 'outgoingTotal', label: 'Outgoing Total' },
    { key: 'outgoingAvgPerHour', label: 'Outgoing Avg/Hour' }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="mx-auto lg:max-w-3xl px-4 sm:px-6 lg:px-8 py-6">
        {/* Header Card */}
        <Card className="mb-8">
          <CardHeader>
            <div className="flex items-center gap-3">
              <Phone className="w-8 h-8 text-[#48B527]" />
              <div>
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
                  Summary Detail of Each User
                </h1>
                <p className="text-gray-500 mt-1 text-sm">
                  Represents the Calling History of Each User
                </p>
              </div>
            </div>
          </CardHeader>
        </Card>

        {/* Date Filter Card */}
        <Card className="mb-8 w-full">
          <CardContent>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div className="space-y-2">

                
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <Calendar className="w-4 h-4" />
                  Start Date
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date | null) => setStartDate(date)}
                  selectsStart
                  startDate={startDate || undefined}
                  endDate={endDate || undefined}
                  placeholderText="Select start date"
                  className="w-full p-3 border border-gray-200 rounded-lg shadow-sm focus:ring-2 focus:ring-[#48B527] focus:border-transparent"
                />
              </div>

              <div className="space-y-2">
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
                  <Calendar className="w-4 h-4" />
                  End Date
                </label>
                <DatePicker
                  selected={endDate}
                  onChange={(date: Date | null) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate || undefined}
                  endDate={endDate || undefined}
                  minDate={startDate || undefined}
                  placeholderText="Select end date"
                  className="w-full p-3 border border-gray-200 rounded-lg shadow-sm focus:ring-2 focus:ring-[#48B527] focus:border-transparent"
                />
              </div>
            </div>

            <button
              onClick={handleDateSubmit}
              disabled={isSubmitting || !startDate || !endDate}
              className="mt-6 w-full flex items-center justify-center gap-2 bg-green-600 hover:bg-green-600 text-white font-medium py-3 px-4 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <span>Processing...</span>
              ) : (
                <>
                  <Clock className="w-4 h-4" />
                  <span>Apply Date Range</span>
                </>
              )}
            </button>
          </CardContent>
        </Card>

        {/* Desktop Table View */}
        <div className="hidden md:block">
          <Card className="">
            <CardContent>
              <div className="overflow-x-auto">
                <table className=" table-auto divide-y divide-gray-200">
                  <thead>
                    <tr>
                      {columns.map(({ key, label }) => (
                        <th
                          key={key}
                          onClick={() => handleSort(key)}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-50"
                        >
                          <div className="flex items-center gap-1">
                            {label}
                            <ArrowUpDown className="w-4 h-4" />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredData &&
                      filteredData
                        .filter((data) => userList.includes(data.user_id))
                        .map((item, index) => (
                          <tr key={index} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {item.user_id}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {item.agentName}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {item.totalCalls}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {(item.totalTalkTime / 60).toFixed(2)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {item.incomingTotal}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {item.incomingMissed}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {item.incomingAvgPerHour?.toFixed(2)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {item.incomingTalkTime?.toFixed(2)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {item.outgoingTotal}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {item.outgoingAvgPerHour?.toFixed(2)}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Mobile Card View */}
        <div className="md:hidden space-y-4">
          {filteredData &&
            filteredData
              .filter((data) => userList.includes(data.user_id))
              .map((item, index) => (
                <Card key={index} className="p-4">
                  <div className="flex justify-between items-center border-b pb-2 mb-2">
                    <div className="text-base font-semibold text-gray-800">
                      {item.agentName}
                    </div>
                    <div className="text-sm text-gray-500">
                      ID: {item.user_id}
                    </div>
                  </div>
                  <div className="space-y-1 text-sm text-gray-700">
                    <p>
                      <span className="font-medium">Total Calls:</span>{" "}
                      {item.totalCalls}
                    </p>
                    <p>
                      <span className="font-medium">Total Talk Time:</span>{" "}
                      {(item.totalTalkTime / 60).toFixed(2)} min
                    </p>
                    <p>
                      <span className="font-medium">Incoming Total:</span>{" "}
                      {item.incomingTotal}
                    </p>
                    <p>
                      <span className="font-medium">Incoming Missed:</span>{" "}
                      {item.incomingMissed}
                    </p>
                    <p>
                      <span className="font-medium">Incoming Avg/Hour:</span>{" "}
                      {item.incomingAvgPerHour?.toFixed(2)}
                    </p>
                    <p>
                      <span className="font-medium">Incoming Talk Time:</span>{" "}
                      {item.incomingTalkTime?.toFixed(2)}
                    </p>
                    <p>
                      <span className="font-medium">Outgoing Total:</span>{" "}
                      {item.outgoingTotal}
                    </p>
                    <p>
                      <span className="font-medium">Outgoing Avg/Hour:</span>{" "}
                      {item.outgoingAvgPerHour?.toFixed(2)}
                    </p>
                  </div>
                </Card>
              ))}
        </div>
      </div>
    </div>
  );
};

export default SummaryDetail;

