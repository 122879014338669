import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MenuHeader from "../../Component/MenuHeader";
import SideMenu from "../SideMenu";
import Main from "../../Component/Data/Main";
import DetailSummary from "../../Component/DetailSummary/DetailSummary";
import Settings from "../Settings/Settings";
import SummaryDetail from "../Data/SummaryDetail";
import EmailPlan from "../Data/EmailPlan";
import { PageContext } from "../../utils/contexts";
import { DEFAULT_COOKIE_DELETE, DEFAULT_COOKIE_GETTER } from "../../helper/Cookie";
import getApi from "../../helper/getApi";
import { Menu, X } from "lucide-react";

const DashBoard = () => {
  const navigate = useNavigate();
  const [pages, setPages] = useState("1");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    // Adjust collapse based on screen size dynamically
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsCollapsed(true);
        setIsMobileMenuOpen(false);
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const activationToken = await DEFAULT_COOKIE_GETTER("access_token");
      const headers = {
        Authorization: "Bearer " + activationToken,
        Accept: "*/*",
        "Access-Control-Allow-Origin": true,
      };

      if (!activationToken) {
        navigate("/Login");
      }

      const response:any = await getApi("auth/test-token", "", headers);
      if (!response || response.message !== "Token is valid") {
        await DEFAULT_COOKIE_DELETE("access_token");
        await DEFAULT_COOKIE_DELETE("user");
        navigate("/Login");
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [navigate]);

  const handlePageChange = (newPage:any) => {
    setPages(newPage);
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <MenuHeader />
      
      {/* Mobile Menu Button */}
      <div className="lg:hidden bg-green-600 p-4 sticky top-0 z-2">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="text-white p-2"
        >
          {isMobileMenuOpen ? (
            <X className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </button>
      </div>

      <div className="flex flex-1 relative">
        {/* Mobile Menu Overlay */}
        {isMobileMenuOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          />
        )}

        {/* Sidebar */}
        <aside
          className={`
            fixed lg:relative h-screen 
            bg-white shadow-lg
            transition-all duration-300 ease-in-out
            ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
            ${isCollapsed ? 'w-20' : 'w-64'}
            z-50 lg:z-auto
          `}
        >
          <PageContext.Provider value={{ 
            pages, 
            setPages: handlePageChange,
            isCollapsed,
            setIsCollapsed 
          }}>
            <SideMenu />
          </PageContext.Provider>
        </aside>

        {/* Main Content */}
        <main className={`flex-1 p-4 transition-all duration-300 ${isCollapsed ? 'lg:ml-10' : 'lg:ml-14'}`}>
          {pages === "1" ? (
            <Main />
          ) : pages === "2" ? (
            <DetailSummary />
          ) : pages === "3" ? (
            <SummaryDetail />
          ) : pages === "4" && JSON.parse(localStorage.getItem("user_data") || "{}").role === "Admin" ? (
            <Settings />
          ) : pages === "5" || (pages === "4" && !(JSON.parse(localStorage.getItem("user_data") || "{}").role === "Admin")) ? (
            <EmailPlan />
          ) : null}
        </main>
      </div>
    </div>
  );
};

export default DashBoard;