import React, { useState } from "react";
import { useFormik } from "formik";
import { Eye, EyeOff, X, Loader } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { ValidationUserCreate } from "../../utils/validateApi";
import useToggle from "../../utils/useToggle";

const CreateUser = ({ props }: { props: (state: boolean) => void }) => {
  const [isPasswordVisible, togglePasswordVisibility] = useToggle(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validate: ValidationUserCreate,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      setIsLoading(true);
      toast.success("User created successfully!");

      setTimeout(() => {
        setIsLoading(false);
        props(false);
      }, 2000);
    },
  });

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
        <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-6">
          {/* Header */}
          <div className="flex justify-between items-center border-b pb-3">
            <h2 className="text-xl font-semibold">Create Manager</h2>
            <button onClick={() => props(false)} className="text-gray-500 hover:text-gray-800">
              <X size={24} />
            </button>
          </div>

          {/* Form */}
          <form className="mt-4 space-y-4" onSubmit={formik.handleSubmit}>
            <div>
              <label className="block text-gray-600 font-medium">Name</label>
              <input
                {...formik.getFieldProps("name")}
                type="text"
                placeholder="Enter name"
                className="w-full mt-1 p-2 border rounded-md focus:ring focus:ring-green-300"
              />
            </div>

            <div>
              <label className="block text-gray-600 font-medium">Email</label>
              <input
                {...formik.getFieldProps("email")}
                type="email"
                placeholder="example@mail.com"
                className="w-full mt-1 p-2 border rounded-md focus:ring focus:ring-green-300"
              />
            </div>

            <div className="relative">
              <label className="block text-gray-600 font-medium">Password</label>
              <input
                {...formik.getFieldProps("password")}
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Enter password"
                className="w-full mt-1 p-2 border rounded-md focus:ring focus:ring-green-300"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-9 right-3 text-gray-500 hover:text-gray-700"
              >
                {isPasswordVisible ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>

            {/* Submit Button with Loader */}
            <button
              type="submit"
              className="w-full flex items-center justify-center bg-green-600 text-white py-2 rounded-md hover:bg-green-700 transition-all disabled:opacity-75"
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader className="animate-spin" size={20} />
              ) : (
                "Create User"
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
