
import React, { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import env from "react-dotenv";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { DEFAULT_COOKIE_DELETE } from "../helper/Cookie";
const MenuHeader = () => {
  const push:any  = useNavigate();

  const Menu = [
    "Profile Update",
    "Menu",
    "Screens",
    "Authenticate Screens",
    "User",
    "Product Settings",
    "Page Settings",
    "Tab Settings",
    "Logout",
  ];
  const menuRef = useRef();
  const imgRef = useRef();

  const handleLogout = async () => {
    await DEFAULT_COOKIE_DELETE("access_token");
    await DEFAULT_COOKIE_DELETE("user");
    localStorage.removeItem("user_data");
      push("/Login");


};
  const [open, setOpen] = useState(false);
  const handleUsers = () =>{
    push("/managers");

  }
  const handleOption = (menu: any) => {
    // let user = localStorage.getItem("user_data");
    // let role = JSON.parse(user).role;
    if(menu === "Users")
    {
        push("/managers");

    }


    if (menu === "Logout") {
      let user = localStorage.getItem("user_data");

      localStorage.removeItem("user_data");
      
      const userValues = { username: user };

      const logout = axios
        .post(env.API_URL + "auth/logout", userValues, {})
        .then((response: any) => {
          if (response.status == 200) {
            localStorage.removeItem("user");
            push("/login");
          }
        })
        .catch(() => {});
    }
  };

  return (
    <>
      <div className=" flex   flex-col ">
        <nav className="bg-green-600 h-20 ">
          <div className="mx-auto   ">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center rounded-full">
                  <Link to="/dashboard">
                    <img
                      className="block  w-56 h-44 lg:hidden"
                      src={logo}
                      alt="Sip Desk"
                    />
                  </Link>
                  <Link to="/dashboard">
                    <img
                      className="hidden  w-56 h-44 lg:block"
                      src={logo}
                      alt="Sip Disk"
                    />
                  </Link>
                </div>
              </div>

              <div className=" absolute lg:mt-1 mt-4 inset-y-0 right-0 flex flex-col items-center pr-2 sm:static sm:inset-auto  ">
                <div className="relative ">
                  <div className="h-auto  bg-extra-color  border-solid border-white flex justify-center pt-0 ">
                    <button
                      type="button"
                      className="flex rounded-full bg-maincolor text-sm focus:outline-none ring-2 ring-white focus:ring-white focus:ring-offset-2 focus:ring-offset-white"
                    >
                      <img
                        onClick={() => setOpen(!open)}
                        src={logo}
                        alt="user"
                        className=" h-10 w-10 object-cover border-4 border-maincolor rounded-full cursor-pointer  "
                      ></img>
                    </button>

                    {open && (
                      <div className="bg-white p-4 w-52 absolute h-max shadow-lg z-20 top-10 right-2">
                        <ul>
                          {/* <li className="font-dmsans p-2 text-md cursor-pointer rounded hover:bg-green-600 bg-opacity-75 hover:text-white">
                            Dark Mode
                          </li>
                           
                           */}
                           {/* {JSON.parse (localStorage.getItem("user_data") || '{}').role == "Admin" ?   */}
                            <li onClick={handleUsers} className="font-dmsans p-2 text-md cursor-pointer rounded hover:bg-green-600 bg-opacity-75 hover:text-white">
                            Managers
                          </li>
                          {/* :<></>
                            
                            } */}
                            


                          <li onClick={handleLogout} className="font-dmsans p-2 text-md cursor-pointer rounded hover:bg-green-600 bg-opacity-75 hover:text-white">
                            Logout
                          </li>


                          {/* { filteredMenu.map((menu:any) => (
                          <li
                            onClick={() => {
                              setOpen(false);
                              handleOption(menu);
                            }}
                            className="font-dmsans p-2 text-md cursor-pointer rounded hover:bg-maincolor-light bg-opacity-75 hover:text-white"
                            key={menu}
                          >
                            {menu}
                          </li>
                        ))} */}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default MenuHeader;
