import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { X, Loader2 } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { ValidateTeamUser } from "../../utils/validateApi";
import { DEFAULT_COOKIE_GETTER } from "../../helper/Cookie";
import getApi from "../../helper/getApi";

const UserUpdateModal = ({ userState, props, menudata }: any) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false); // Loader for form submission

  const formik = useFormik({
    initialValues: {
      user: "",
      email: userState.email,
    },
    validate: ValidateTeamUser,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values: any) => {
      setSubmitting(true); // Show loader on button
      setTimeout(() => {
        setSubmitting(false);
        toast.success("Team member added successfully!"); // Show success toast
        props(false);
        // dispatch(menudata);
      }, 1500); // Simulating API call delay
    },
  });

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const activationToken = await DEFAULT_COOKIE_GETTER("access_token");
        const headers = {
          Authorization: `Bearer ${activationToken}`,
        };

        const response: any = await getApi("auth/get_team_members", "", headers);
        const results: any = [];

        response?.data.forEach((value: any, index: number) => {
          if (index === 0) {
            formik.values.user = `${value.first_name}:${value.user}`;
            setData(`${value.first_name}:${value.user}`);
          }
          results.push({
            key: index,
            value: `${value.first_name}:${value.user}`,
            user: value.first_name,
          });
        });

        setOptions(results);
      } catch (error) {
        console.error("Error fetching team members:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [userState]);

  const optionElements = useMemo(() => {
    return options.map((option: any) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [options]);

  return (
    <>
      <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg w-96 md:w-[500px] p-6 relative">
          {/* Close Button */}
          <button className="absolute top-4 right-4 text-gray-500 hover:text-gray-700" onClick={() => props(false)}>
            <X size={24} />
          </button>

          {/* Modal Title */}
          <h2 className="text-2xl font-semibold text-gray-700 text-center mb-4">Add Team Member</h2>

          {/* Loader while fetching */}
          {loading ? (
            <div className="flex justify-center items-center py-6">
              <Loader2 className="animate-spin text-gray-600" size={32} />
            </div>
          ) : (
            <form className="space-y-4" onSubmit={formik.handleSubmit}>
              {/* Select Team */}
              <div>
                <label className="block text-gray-600 mb-1">Select Team</label>
                <select
                  {...formik.getFieldProps("user")}
                  value={data}
                  onChange={(e) => {
                    setData(e.target.value);
                    formik.setFieldValue("user", e.target.value);
                  }}
                  className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring focus:ring-green-300"
                >
                  {optionElements}
                </select>
              </div>

              {/* Submit Button with Loader */}
              <button
                type="submit"
                className="w-full py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition flex justify-center items-center"
                disabled={submitting}
              >
                {submitting ? <Loader2 className="animate-spin mr-2" size={20} /> : "Add"}
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(UserUpdateModal);
