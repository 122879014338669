import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContext } from '../utils/contexts';
import { 
  Home, 
  Database, 
  PhoneCall,
  BarChart3,
  Mail,
  Settings,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';

const SideMenu = () => {
  const navigate = useNavigate();
  const { pages, setPages, isCollapsed, setIsCollapsed } = useContext<any>(PageContext);
  const userRole = JSON.parse(localStorage.getItem("user_data") || '{}').role;
  const isAdmin = userRole === "Admin";

  const menuItems = [
    { 
      name: "Idle Status", 
      icon: Home,
      id: "1"
    },
    { 
      name: "Call History", 
      icon: PhoneCall,
      id: "2"
    },
    { 
      name: "Call Statistics", 
      icon: BarChart3,
      id: "3"
    },
    ...(isAdmin ? [{
      name: "User Email Notifications",
      icon: Mail,
      id: "4"
    }] : []),
    {
      name: "Team Email Notifications",
      icon: Settings,
      id: isAdmin ? "5" : "4"
    }
  ];

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMenuClick = (id:any) => {
    setPages(id);
    if (window.location.pathname !== '/dashboard') {
      navigate('/dashboard');
    }
  };

  return (
<div className="h-screen flex flex-col p-4 overflow-y-auto bg-white shadow-md">
{/* Collapse Toggle Button */}
      <button 
        onClick={handleCollapseToggle}
        className="p-2 mb-4 self-end text-green-800 hover:bg-green-50 rounded-lg transition-colors"
        aria-label="Toggle menu"
      >
        {isCollapsed ? (
          <ChevronRight className="w-6 h-6" />
        ) : (
          <ChevronLeft className="w-6 h-6" />
        )}
      </button>

      <div className="space-y-2">
        {menuItems.map((item) => {
          const Icon = item.icon;
          const isActive = pages === item.id;
          
          return (
            <button
              key={item.id}
              onClick={() => handleMenuClick(item.id)}
              className={`
                w-full flex items-center px-3 py-2 rounded-lg transition-all
                ${isActive 
                  ? 'bg-green-100 text-green-800' 
                  : 'text-gray-600 hover:bg-gray-100'
                }
              `}
            >
              <Icon className="w-5 h-5 flex-shrink-0" />
              <span className={`
                ml-3 text-sm font-medium whitespace-nowrap
                ${isCollapsed ? 'hidden' : 'block'}
              `}>
                {item.name}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SideMenu;