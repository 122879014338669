import React, { useState } from "react";
import UserUpdateModal from "./UserUpdateModal";
import { Trash2 } from "lucide-react";
import postApi from "../../helper/postApi";
import { DEFAULT_COOKIE_GETTER } from "../../helper/Cookie";

const Card = ({ children, className = "" }: { children: React.ReactNode; className?: string }) => (
  <div className={`bg-white rounded-lg shadow-lg p-4 ${className}`}>{children}</div>
);

const CardHeader = ({ children }: { children: React.ReactNode }) => (
  <div className="px-6 py-5 border-b border-gray-200 font-bold text-lg text-gray-900">
    {children}
  </div>
);

const CardContent = ({ children }: { children: React.ReactNode }) => <div className="p-6">{children}</div>;

const TeamDetails = ({ currentItems }: any) => {
  const [userState, setUserState] = useState({});
  const userData = JSON.parse(localStorage.getItem("user_data") || "{}");
  const [showModal, setShowModal] = useState(false);

  const handleTeamMemberDelete = async (element: any, User: any) => {
    try {
      const activationToken = await DEFAULT_COOKIE_GETTER("access_token");
      const headers = {
        Authorization: `Bearer ${activationToken}`,
        Accept: "*/*",
        "Access-Control-Allow-Origin": "true",
      };

      await postApi("data/deleteTeamMember", { user: element, manager: User }, headers);

    } catch (e) {
      console.error("Error deleting team member:", e);
    }
  };

  return (
    <>
      {showModal && <UserUpdateModal props={setShowModal} userState={userState} userData={userData} />}
      <Card className="mt-4 w-full">
        <CardHeader>Manager's Team</CardHeader>
        <CardContent>
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {currentItems &&
              currentItems.map((item: any, index: number) => (
                <Card key={index} className="border border-gray-200">
                  <CardHeader>{item?.User}</CardHeader>
                  <CardContent>
                    <div className="space-y-2">
                      {item?.team.map((element: any, idx: number) => (
                        <div key={idx} className="flex items-center justify-between p-2 bg-gray-100 rounded-md">
                          <span className="text-gray-800">{element}</span>
                          <button
                            onClick={async () => await handleTeamMemberDelete(element, item.User)}
                            className="text-red-600 hover:text-red-800"
                          >
                            <Trash2 className="w-5 h-5" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              ))}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default TeamDetails;
