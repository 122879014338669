import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  UserPlus,
  Search,
  Users,
  Settings,
  Filter,
  RefreshCw,
  UserCog,
  Users2,
  Menu,
  X,
} from "lucide-react";
import { RootState, AppDispatch } from "../../redux/store";
import { userData } from "../../redux/dataStore/userSlice";
import SideMenu from "../SideMenu";
import UserDetails from "./UserDetails";
import CreateUser from "./CreateUser";
import ToasterGen from "../../helper/Toaster";
import { PageContext } from "../../utils/contexts";
import MenuHeader from "../MenuHeader";
import { fetchTeams } from "../../redux/dataStore/teamSlice";
import TeamDetails from "./TeamDetails";
import getApi from "../../helper/getApi";
import { DEFAULT_COOKIE_DELETE, DEFAULT_COOKIE_GETTER } from "../../helper/Cookie";

interface User {
  id: number;
  name: string;
  email: string;
  company: string;
  website: string;
}

const TabButton = ({ active, onClick, children }: any) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 font-semibold rounded-t-lg transition-colors ${
      active
        ? "bg-white text-green-600 border-t-2 border-green-600"
        : "text-gray-600 hover:text-gray-800 hover:bg-gray-50"
    }`}
  >
    <div className="flex items-center gap-2">{children}</div>
  </button>
);

const UserList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const push: any = useNavigate();

  const [circularProgress, setCircularProgress] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [type, setType] = useState<boolean>(false);
  const [deletedState, setDeletedState] = useState<number>(0);
  const [filter, setFilter] = useState<boolean>(true);
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
  const [sideMenuShow, setSideMenuShow] = useState<boolean>(false);
  const [userState, setUserState] = useState<any>({});
  const [pages, setPages] = useState("1");
  const [selectedFilters, setSelectedFilters] = useState<{ Website: string }>({
    Website: "",
  });
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const itemsPerPage = 3;
  const user = useSelector((state: RootState) => state?.users);
  const teams = useSelector((state: RootState) => state?.teams);
  const [collapse, setCollapse] = useState(true);
  const [activeTab, setActiveTab] = useState("managers");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(user.userCount / itemsPerPage) || 0;
  useEffect(() => {
    // Adjust collapse based on screen size dynamically
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setIsCollapsed(true);
        setIsMobileMenuOpen(false);
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    const interval = setInterval(async () => {
      const activationToken = await DEFAULT_COOKIE_GETTER("access_token");
      const headers = {
        Authorization: "Bearer " + activationToken,
        Accept: "*/*",
        "Access-Control-Allow-Origin": true,
      };
      const accT: any = await DEFAULT_COOKIE_GETTER("access_token");

      if (!accT) {
        push("/Login");
      }

      const response: any = await getApi("auth/test-token", "", headers);

      if (!(response && response?.message === "Token is valid")) {
        await DEFAULT_COOKIE_DELETE("access_token");
        await DEFAULT_COOKIE_DELETE("user");
        push("/Login");
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [push]);

  const handleColumnSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const getPages = () => {
    const maxVisiblePages = 5;
    const sidePages = Math.floor((maxVisiblePages - 3) / 2);
    const pagesArray: JSX.Element[] = [];

    pagesArray.push(
      <button
        key="1"
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 font-bold rounded mx-1 italic ${
          currentPage === 1
            ? "bg-textColor text-white"
            : "bg-textColor text-white"
        }`}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - sidePages);
    let endPage = Math.min(totalPages - 1, currentPage + sidePages);
    let addEndDots = endPage < totalPages - 1;

    if (addEndDots) {
      pagesArray.push(
        <span key="end-dots" className="px-0 py-1 text-gray-600">
          ...
        </span>
      );
    }

    for (let page = startPage; page <= endPage; page++) {
      pagesArray.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={`px-3 mx-1 py-1 font-bold rounded ${
            currentPage === page
              ? "bg-textColor text-white"
              : "bg-textColor text-white"
          }`}
        >
          {page}
        </button>
      );
    }

    if (addEndDots) {
      pagesArray.push(
        <span key="end-dots" className="px-0 py-1 text-gray-600">
          ...
        </span>
      );
    }

    pagesArray.push(
      <button
        key={totalPages}
        onClick={() => handlePageChange(totalPages)}
        className={`px-3 py-1 font-bold rounded mx-1 italic ${
          currentPage === totalPages
            ? "bg-textColor text-white"
            : "bg-textColor text-white"
        }`}
      >
        {totalPages}
      </button>
    );

    return pagesArray;
  };

  useLayoutEffect(() => {
    if (location.state) {
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        category: location.state,
      }));
    }
  }, [location.state]);

  useEffect(() => {
    if (localStorage.getItem("user_data")) {
      const role = JSON.parse(localStorage.getItem("user_data") || "{}").role;
      // Use role if needed
    }
  }, []);

  useEffect(() => {
    if (user.users && user.users.length > 0) {
      // Optionally perform additional actions if needed
    }
  }, [user.users, showModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(fetchTeams());
      dispatch(
        userData({
          page: currentPage,
          searchQuery: searchQuery,
          selectedFilters: selectedFilters,
          sortColumn: sortColumn,
          sortDirection: sortDirection,
        })
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [
    currentPage,
    searchQuery,
    selectedFilters,
    sortColumn,
    sortDirection,
    type,
    dispatch,
    showModalCreate,
  ]);

  const isLoading = !user.users;

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <MenuHeader />
      <ToasterGen />

      {/* Mobile Side Menu Toggle */}
      <div className="lg:hidden bg-green-600 p-4 sticky top-0 z-2">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="text-white p-2"
        >
          {isMobileMenuOpen ? (
            <X className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </button>
      </div>

      <div className="flex flex-1 relative">
        {/* Desktop Side Menu */}
          {/* Mobile Menu Overlay */}
          {isMobileMenuOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
            onClick={() => setIsMobileMenuOpen(false)}
          />
        )}

        {/* Sidebar */}
        <aside
          className={`
            fixed lg:relative h-screen
            bg-white shadow-lg
            transition-all duration-300 ease-in-out
            ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
            ${isCollapsed ? 'w-20' : 'w-64'}
            z-50 lg:z-auto
          `}
        >
          <PageContext.Provider value={{ 
            pages, 
            setPages: handlePageChange,
            isCollapsed,
            setIsCollapsed 
          }}>
            <SideMenu />
          </PageContext.Provider>
        </aside>



        {/* Main Content */}
        <main
         className={`flex-1 p-4 transition-all duration-300 ${isCollapsed ? 'lg:ml-10' : 'lg:ml-14'}`}
        >
          <div className="mb-8">
            <div className="flex items-center gap-3 mb-2">
              <Settings className="w-6 h-6 text-green-600" />
              <h2 className="text-2xl font-bold text-gray-800">
                Manager's Setting
              </h2>
            </div>
            <p className="text-gray-500 ml-9">
              Create and manage team leaders and their members
            </p>
          </div>

          <div className="flex gap-2 mb-4">
            <TabButton
              active={activeTab === "managers"}
              onClick={() => setActiveTab("managers")}
            >
              <UserCog className="w-5 h-5" />
              <span>Manager Settings</span>
            </TabButton>

            <TabButton
              active={activeTab === "users"}
              onClick={() => setActiveTab("users")}
            >
              <Users className="w-5 h-5" />
              <span>User Management</span>
            </TabButton>

            {JSON.parse(localStorage.getItem("user_data") || "{}").role ===
              "Admin" && (
              <TabButton
                active={activeTab === "teams"}
                onClick={() => setActiveTab("teams")}
              >
                <Users2 className="w-5 h-5" />
                <span>Team Overview</span>
              </TabButton>
            )}
          </div>

          {activeTab === "managers" &&
            JSON.parse(localStorage.getItem("user_data") || "{}").role ===
              "Admin" && (
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-6">
                <div className="flex justify-between items-center">
                  <button
                    onClick={() => setShowModalCreate(true)}
                    className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                  >
                    <UserPlus className="w-5 h-5" />
                    <span>Create Manager</span>
                  </button>
                </div>
              </div>
            )}

          {activeTab === "users" && (
            <>
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-6">
                <div className="flex items-center gap-2 mb-6">
                  <Users className="w-5 h-5 text-green-600" />
                  <h3 className="text-lg font-semibold text-gray-700">
                    User Management
                  </h3>
                </div>

                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center gap-2">
                    <Filter className="w-5 h-5 text-gray-500" />
                    <h3 className="text-lg font-semibold text-gray-700">
                      Filters
                    </h3>
                  </div>
                  <button
                    onClick={() => setFilter(!filter)}
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  >
                    {filter ? (
                      <ChevronUp className="w-5 h-5 text-gray-500" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-gray-500" />
                    )}
                  </button>
                </div>

                <div
                  className={`${
                    filter ? "block" : "hidden"
                  } transition-all duration-300`}
                >
                  <div className="relative mb-6">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 focus:border-green-500 focus:ring-2 focus:ring-green-200 transition-all"
                      placeholder="Search by name..."
                    />
                  </div>
                </div>

                <UserDetails
                  handleColumnSort={handleColumnSort}
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  currentItems={user.users as User[]}
                  deletedState={setDeletedState}
                  typeNew={setType}
                  dispatch={dispatch}
                />
              </div>
              <div className="flex justify-end gap-2 mt-6">
                <div className="flex items-center bg-white rounded-lg shadow-sm border border-gray-100">
                  <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className="p-2 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ChevronLeft className="w-5 h-5 text-gray-700" />
                  </button>

                  <div className="px-4 py-2 border-l border-r border-gray-100">
                    {getPages()}
                  </div>

                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="p-2 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ChevronRight className="w-5 h-5 text-gray-700" />
                  </button>
                </div>
              </div>
            </>
          )}

          {activeTab === "teams" &&
            JSON.parse(localStorage.getItem("user_data") || "{}").role ===
              "Admin" && (
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 mb-6">
                <div className="flex items-center gap-2 mb-6">
                  <button
                    onClick={() => dispatch(fetchTeams())}
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  >
                    <RefreshCw className="w-5 h-5 text-gray-500" />
                  </button>

                  <Users className="w-5 h-5 text-green-600" />
                  <h3 className="text-lg font-semibold text-gray-700">
                    Team Overview
                  </h3>
                </div>
                <TeamDetails currentItems={teams && teams.teams} />
              </div>
            )}
        </main>
      </div>

      {showModalCreate && <CreateUser props={setShowModalCreate} />}
    </div>
  );
};

export default UserList;
