import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "../..//components/ui/button";
import { Separator } from "../../components/ui/separator";

import UserUpdateModal from "./UserUpdateModal";
import { Trash2, UserPlus } from "lucide-react";
import { DEFAULT_COOKIE_GETTER } from "../../helper/Cookie";
import deleteApi from "../../helper/deleteApi";
import "./UserDetail.css";
const Card = ({ children, className = "" }: { children: React.ReactNode; className?: string }) => (
  <div className={`bg-white rounded-lg shadow-lg ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children }: { children: React.ReactNode }) => (
  <div className="px-6 py-5 border-b border-gray-100">
    {children}
  </div>
);

const CardContent = ({ children }: { children: React.ReactNode }) => (
  <div className="p-6">
    {children}
  </div>
);

const UserDetails = ({ currentItems, deletedState, typeNew }: any) => {
  const userData = JSON.parse(localStorage.getItem("user_data") || "{}");
  const isAdmin = userData.role === "Admin";
  const userEmail = userData.email;

  const filteredItems = currentItems?.filter((item: any) =>
    isAdmin ? true : item.email === userEmail
  );

  const [userState, setUserState] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleUpdateClick = (item: any) => {
    setUserState(item);
    setShowModal(true);
  };

  const handleDeleteButton = async (ID: any) => {
    const activationToken = await DEFAULT_COOKIE_GETTER("access_token");
    const headers = {
      Authorization: `Bearer ${activationToken}`,
      Accept: "*/*",
      "Access-Control-Allow-Origin": "true",
    };
    await deleteApi("auth/deleteUser", ID, headers);
    typeNew((prev: any) => !prev);
    deletedState((prev: any) => prev + 1);
  };

  return (
    <div className="w-full p-6">
      {showModal && (
        <UserUpdateModal props={setShowModal} userState={userState} userData={userData} />
      )}
      
      <h1 className="text-2xl font-bold mb-4">Managers</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredItems?.map((item: any, index: number) => (
          <Card key={index} className="shadow-md border rounded-xl p-4">
            <CardHeader>
              <h2 className="text-lg font-semibold">{item.name}</h2>
              <p className="text-gray-500 text-sm">{item.email}</p>
            </CardHeader>
            <Separator className="my-3" />
            <CardContent >
              <Button
                onClick={() => handleUpdateClick(item)}
                className="bg-green-500 text-white hover:bg-green-600 mx-2"
              >
                <UserPlus className="w-5 h-5 mr-2" /> Add Team Member
              </Button>
              {isAdmin && (
                <Button
                  onClick={() => handleDeleteButton(item._id)}
                  className="bg-red-500 text-white hover:bg-red-600"
                >
                  <Trash2 className="w-5 h-5 mr-2" /> Delete
                </Button>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default UserDetails;
