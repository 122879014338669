import React, { useEffect, useRef } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Mail } from "lucide-react";

import { DEFAULT_COOKIE_GETTER } from "../../helper/Cookie";
import postApi from "../../helper/postApi";
import getApi from "../../helper/getApi";
import ToasterGen from "../../helper/Toaster";

// Form validation schema
const formSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email format!" })
    .min(1, { message: "Email is required!" }),
  time: z.string().min(1, { message: "Time is required!" }),
});

// Custom Card Components
const Card = ({ children, className = "" }: { children: React.ReactNode; className?: string }) => (
  <div className={`bg-white rounded-lg shadow-lg ${className}`}>{children}</div>
);

const CardHeader = ({ children }: { children: React.ReactNode }) => (
  <div className="px-6 py-5 border-b border-gray-100">{children}</div>
);

const CardContent = ({ children }: { children: React.ReactNode }) => <div className="p-6">{children}</div>;

const Settings = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { email: "", time: "" },
  });

  const initialLoad = useRef(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const activationToken = await DEFAULT_COOKIE_GETTER("access_token");
        const headers = {
          Authorization: `Bearer ${activationToken}`,
          Accept: "*/*",
          "Access-Control-Allow-Origin": true,
        };

        const { data, error }: any = await getApi("data/settings_data", {}, headers);

        if (data?.time && initialLoad.current) {
          console.log('Received settings:', data.time); // Add debug log
          reset({
            time: data.time.toString(),
            email: data.email,
          });
          initialLoad.current = false;
        }
      } catch (err) {
        toast.error("Network Error!");
      }
    }

    fetchData();
  }, [reset]);

  const onSubmit = async (payload: any) => {
    try {
      const activationToken = await DEFAULT_COOKIE_GETTER("access_token");
      const headers = {
        Authorization: `Bearer ${activationToken}`,
        Accept: "*/*",
        "Access-Control-Allow-Origin": true,
      };

      const { data, error } = await postApi("data/settings", payload, headers);

      if (data?.message && !error?.message) {
        toast.success(data.message);
      } else {
        toast.error(error.message);
      }
    } catch (err) {
      toast.error("Network Error!");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <ToasterGen />

      <Card className="max-w-4xl mx-auto mb-8">
        <CardHeader>
          <div className="flex items-center gap-3">
            <Mail className="w-8 h-8 text-[#48B527]" />
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Email Alert Settings</h1>
              <p className="text-gray-500 mt-1">
                Configure email alerts for manager notifications (admin only)
              </p>
            </div>
          </div>
        </CardHeader>
      </Card>

      <Card className="max-w-4xl mx-auto">
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-gray-700 text-sm font-medium mb-1">
                Trigger Time
              </label>
        <input
    {...register("time")}
    type="number"
    max={60}
    min={1}
    className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#48B527]"
    placeholder="Enter time in minutes"
  />
              {errors.time && <span className="text-red-500 text-sm">{errors.time.message}</span>}
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-medium mb-1">
                Trigger Email
              </label>
              <input
                {...register("email")}
                type="email"
                className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#48B527]"
                placeholder="Enter email address"
              />
              {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}
            </div>

            <button
              type="submit"
              className="w-full p-3 bg-green-600 text-white font-semibold rounded-md hover:bg-green-600 transition"
            >
              Apply
            </button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Settings;
