import React, { useEffect, useState } from 'react';
import { DEFAULT_COOKIE_GETTER } from '../../helper/Cookie';
import getApi from '../../helper/getApi';
import postApi from '../../helper/postApi';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Mail, Clock, User, AlertCircle } from 'lucide-react';


// Custom Card Components
const Card = ({ children, className = "" }: { children: React.ReactNode; className?: string }) => (
  <div className={`bg-white rounded-lg shadow-lg ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children }: { children: React.ReactNode }) => (
  <div className="px-6 py-5 border-b border-gray-100">
    {children}
  </div>
);

const CardContent = ({ children }: { children: React.ReactNode }) => (
  <div className="p-6">
    {children}
  </div>
);

const EmailPlan = () => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [userList, setUserList] = useState<string[]>([]);
  const [progress, setProgress] = useState(0);

  const formSchema = z.object({
    email: z.string().email().min(1, { message: 'Email is Required!' }),
    time: z.string().min(1, { message: 'Time is Required!' }),
    user: z.string().min(1, { message: 'User is Required!' }),
  });

  type SettingsSchema = z.infer<typeof formSchema>;
  const { register, handleSubmit, setValue, formState: { errors, isSubmitting } } = useForm<SettingsSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      time: '',
      user: '',
    },
  });
  

  const onSubmit = async (payload: SettingsSchema) => {
    setProgress(progress + 10);
    try {
      const activationToken = await DEFAULT_COOKIE_GETTER('access_token');
      const headers = {
        'Authorization': 'Bearer ' + activationToken,
        'Accept': '*/*',
        'Access-Control-Allow-Origin': true,
      };

      if (selectedUser) {
        payload.user = selectedUser;
      } else {
        toast.error('User selection is required.');
        return;
      }

      const { data, error }: any = await postApi('auth/setTime', payload, headers);
      setProgress((prevProgress) => progress + 20);

      if (data && data.message !== '' && !error.message) {
        toast.success(data.message);
        setProgress(100);
      } else {
        toast.error(error.message);
        setProgress(100);
      }
    } catch (err) {
      toast.error('Network Error!');
    }
  };

  useEffect(() => {
    async function fetchUsers() {
      try {
        const activationToken = await DEFAULT_COOKIE_GETTER('access_token');
        const headers = {
          'Authorization': 'Bearer ' + activationToken,
          'Accept': '*/*',
          'Access-Control-Allow-Origin': true,
        };
        let dataEmail = await DEFAULT_COOKIE_GETTER('user');
        const email = JSON.parse(dataEmail || '{}').email;
        const role = JSON.parse(dataEmail || '{}').role;
        
        const response: any = await getApi('auth/get_team_by_email', encodeURIComponent(JSON.stringify({ email, role })), headers);
        setUserList(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchUsers();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <Card className="max-w-4xl mx-auto mb-8">
        <CardHeader>
          <div className="flex items--center gap-3">
            <Mail className="w-8 h-8 text-[#48B527]" />
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Email Alert Settings</h1>
              <p className="text-gray-500 mt-1">Configure email alerts for manager notifications</p>
            </div>
          </div>
        </CardHeader>
      </Card>

      <Card className="max-w-4xl mx-auto">
        <CardContent>
          <div className="mb-8">
            <label className="block text-sm font-medium text-gray-700 mb-2">Select User</label>
            <select
              value={selectedUser || ''}
              onChange={(e) => setSelectedUser(e.target.value)}
              className="w-full p-3 border border-gray-200 rounded-lg shadow-sm focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all"
            >
              <option value="">Select a user</option>
              {userList.map((user) => (
                <option key={user} value={user}>{user}</option>
              ))}
            </select>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="space-y-6">
              <div>
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700 mb-2">
                  <Clock className="w-4 h-4" />
                  Trigger Time (minutes)
                </label>
                <input
                  {...register('time')}
                  type="number"
                  pattern="[0-9]{0,5}"
                  max={60}
                  min={1}
                  className="w-full p-3 border border-gray-200 rounded-lg shadow-sm focus:ring-2 focus:ring-[#48B527] focus:border-transparent transition-all"
                  placeholder="Enter time in minutes"
                />
                {errors.time && (
                  <div className="flex items-center gap-1 mt-1 text-red-500 text-sm">
                    <AlertCircle className="w-4 h-4" />
                    <span>{errors.time.message}</span>
                  </div>
                )}
              </div>

              <div>
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700 mb-2">
                  <Mail className="w-4 h-4" />
                  Email Address
                </label>
                <input
                  {...register('email')}
                  type="email"
                  className="w-full p-3 border border-gray-200 rounded-lg shadow-sm focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all"
                  placeholder="Enter email address"
                />
                {errors.email && (
                  <div className="flex items-center gap-1 mt-1 text-red-500 text-sm">
                    <AlertCircle className="w-4 h-4" />
                    <span>{errors.email.message}</span>
                  </div>
                )}
              </div>

              <div>
                <label className="flex items-center gap-2 text-sm font-medium text-gray-700 mb-2">
                  <User className="w-4 h-4" />
                  Selected User
                </label>
                <input
                  {...register('user')}
                  value={selectedUser || ''}
                  readOnly
                  className="w-full p-3 bg-gray-50 border border-gray-200 rounded-lg"
                />
                {errors.user && (
                  <div className="flex items-center gap-1 mt-1 text-red-500 text-sm">
                    <AlertCircle className="w-4 h-4" />
                    <span>{errors.user.message}</span>
                  </div>
                )}
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full flex items-center justify-center gap-2 bg-green-600 hover:bg-green-600-600 text-white font-medium py-3 px-4 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                <span>Processing...</span>
              ) : (
                <>
                  <Mail className="w-4 h-4" />
                  <span>Apply Settings</span>
                </>
              )}
            </button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default EmailPlan;