import React, { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TypeAnimation } from "react-type-animation";
import logo from "../../assets/logo.png";
import { useForm } from "react-hook-form";
import { DEFAULT_COOKIE_SETTER } from "../../helper/Cookie";
import LoadingBar from "react-top-loading-bar";
import { useNavigate } from "react-router-dom";
import postApi from "../../helper/postApi";
import ToasterGen from "../../helper/Toaster";
import toast from "react-hot-toast";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import useToggle from "../../helper/useToggle";

/* ------------------------- Card Components ------------------------- */
const Card = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={`bg-white rounded-lg shadow-xl border border-gray-100 ${className}`}
  >
    {children}
  </div>
);

const CardHeader = ({ children }: { children: React.ReactNode }) => (
  <div className="px-6 py-5 border-b border-gray-100">
    {children}
  </div>
);

const CardContent = ({ children }: { children: React.ReactNode }) => (
  <div className="p-6">{children}</div>
);
/* ------------------------------------------------------------------ */

const LoginInfo = () => {
  const [progress, setProgress] = useState(0);
  const push: any = useNavigate();
  const [isPasswordHideShow, setPasswordHideShow] = useToggle(false);

  const formSchema = z.object({
    email: z.string().email().min(1, { message: "Email is Required!" }),
    password: z.any(),
  });

  type LoginSchema = z.infer<typeof formSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginSchema>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (payload: LoginSchema) => {
    setProgress(progress + 10);
    try {
      const { data, error }: any = await postApi("auth/getLogin", payload);
      setProgress((prevProgress) => progress + 20);

      if (data && data.message !== "" && error.message == undefined) {
        localStorage.setItem("user_data", JSON.stringify(data.user));
        await DEFAULT_COOKIE_SETTER("access_token", data.accessToken, false);
        await DEFAULT_COOKIE_SETTER(
          "user",
          JSON.stringify({
            email: data.user.email,
            name: data.user.name,
            role: data.user.role,
          }),
          false
        );
        toast.success(data.message);
        setProgress(progress + 30);
        push("/dashboard");
        setProgress(100);
      } else {
        toast.error(error.message);
        setProgress(100);
      }
    } catch (err) {
      toast.error("Network Error!");
      setProgress(100);
    }
  };

  const handleTogglePassword = () => {
    setPasswordHideShow();
  };

  return (
    <React.Fragment>
      <LoadingBar
        color="rgb(95,126,220)"
        progress={progress}
        waitingTime={400}
        onLoaderFinished={() => {
          setProgress(0);
        }}
      />
      <ToasterGen />

      {/* Outer Container */}
      <div className="grid grid-cols-1 lg:grid-cols-5 min-h-screen">
        {/* Left Panel (Logo + Animated Text) */}
        <div className="col-span-2 bg-gradient-to-br from-green-600 to-[#3a9420] flex flex-col justify-center items-center p-6">
          {/* Logo */}
          <img
            src={logo}
            alt="logo"
            className="w-40 h-auto mb-4 drop-shadow-md"
          />
          {/* Animated Text */}
          <div className="text-2xl lg:text-3xl font-semibold text-white text-center">
            <TypeAnimation
              sequence={[
                "Manage Users",
                1000,
                "Update Settings",
                1000,
                "Welcome to Admin",
                1000,
              ]}
              wrapper="span"
              speed={50}
              style={{ display: "inline-block" }}
              repeat={Infinity}
            />
          </div>
        </div>

        {/* Right Panel (Login Form) */}
        <div className="col-span-3 bg-[#F9F9F9] flex items-center justify-center p-4 lg:p-10">
          <Card className="max-w-md w-full">
            <CardHeader>
              <h2 className="text-2xl font-bold text-gray-800 text-center">
                Welcome Back
              </h2>
            </CardHeader>
            <CardContent>
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Email
                  </label>
                  <input
                    {...register("email")}
                    id="email"
                    type="email"
                    className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:border-green-500 focus:ring-2 focus:ring-green-200 transition-all"
                    placeholder="Email Address"
                    autoComplete="on"
                  />
                  {errors.email && (
                    <span className="text-red-500 text-xs mt-1 block">
                      {errors.email.message}
                    </span>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Password
                  </label>
                  <div className="relative">
                    <input
                      {...register("password")}
                      id="password"
                      type={isPasswordHideShow ? "text" : "password"}
                      className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:border-green-500 focus:ring-2 focus:ring-green-200 transition-all pr-10"
                      placeholder="Enter your password"
                      autoComplete="on"
                    />
                    <button
                      type="button"
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                      onClick={handleTogglePassword}
                    >
                      {isPasswordHideShow ? (
                        <VscEye className="w-5 h-5" />
                      ) : (
                        <VscEyeClosed className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  {errors.password?.message && (
  <span className="text-red-500 text-xs mt-1 block">
    {String(errors.password.message)}
  </span>
)}
                </div>

                <div className="text-center">
                  <button
                    className="w-full inline-flex items-center justify-center gap-2 px-4 py-2 bg-green-600 text-white font-semibold rounded-lg hover:bg-[#3a9420] transition-colors focus:outline-none focus:ring-2 focus:ring-green-500"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Processing..." : "Login"}
                  </button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginInfo;
