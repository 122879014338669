import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailData } from "../../redux/dataStore/detailSlice";
import { RootState, AppDispatch } from '../../redux/store';
import { DEFAULT_COOKIE_GETTER } from "../../helper/Cookie";
import getApi from "../../helper/getApi";
import toast from "react-hot-toast";
import { Phone, Search, ArrowUpDown, ChevronLeft, ChevronRight } from 'lucide-react';

// Custom Card Components
const Card = ({ children, className = "" }: { children: React.ReactNode; className?: string }) => (
  <div className={`bg-white rounded-lg shadow-lg ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children }: { children: React.ReactNode }) => (
  <div className="px-4 py-5 sm:px-6 border-b border-gray-100">
    {children}
  </div>
);

const CardContent = ({ children }: { children: React.ReactNode }) => (
  <div className="p-4 sm:p-6">
    {children}
  </div>
);

interface DetailItem {
  user: string;
  dateAndTime: string;
  source: string;
  destination: string;
  duration: string;
  direction: string;
}

type PageNumber = number | '...';

const DetailSummary = () => {
  const dispatch = useDispatch<AppDispatch>();
  const data: any = useSelector((state: RootState) => state?.details);
  const [userList, setUserList] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<DetailItem[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof DetailItem | null; direction: 'asc' | 'desc'; }>({ key: null, direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    async function fetchData() {
      try {
        let dataEmail = await DEFAULT_COOKIE_GETTER("user");
        dataEmail = JSON.parse(dataEmail || "{}").email;
        dispatch(detailData({ searchQuery, dataEmail }));
      } catch (error) {
        toast.error("Failed to fetch data");
      }
    }
    fetchData();
  }, [dispatch, searchQuery]);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const activationToken = await DEFAULT_COOKIE_GETTER("access_token");
        const headers = {
          "Authorization": 'Bearer ' + activationToken,
          "Accept": "*/*",
        };
        
        let dataEmail = await DEFAULT_COOKIE_GETTER("user");
        const email = JSON.parse(dataEmail || '{}').email;
        const role = JSON.parse(dataEmail || '{}').role;
        
        const response: any = await getApi('auth/get_team_by_email', encodeURIComponent(JSON.stringify({ email, role })), headers);
        setUserList(response.data);
      } catch (error) {
        toast.error("Failed to fetch users");
      }
    }
    fetchUsers();
  }, []);

  useEffect(() => {
    if (data?.details?.details) {
      let filtered = [...data.details.details];
      
      if (selectedUser) {
        filtered = filtered.filter((item: DetailItem) => item.user === selectedUser);
      }
      
      if (searchQuery) {
        filtered = filtered.filter((item: DetailItem) =>
          Object.values(item).some(value => value.toString().toLowerCase().includes(searchQuery.toLowerCase()))
        );
      }
      
      if (sortConfig.key) {
        filtered.sort((a, b) => sortConfig.direction === 'asc' 
          ? (a[sortConfig.key!] < b[sortConfig.key!] ? -1 : 1)
          : (a[sortConfig.key!] > b[sortConfig.key!] ? -1 : 1)
        );
      }

      setFilteredData(filtered);
      setCurrentPage(1);
    }
  }, [data, selectedUser, searchQuery, sortConfig]);

  const handleSort = (key: keyof DetailItem) => {
    setSortConfig({ key, direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc' });
  };

  const handlePageSizeChange = (size: number) => {
    setItemsPerPage(size);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginate = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getPageNumbers = (): PageNumber[] => {
    const delta = 2;
    const range: any[] = [];
    
    for (
      let i = Math.max(1, currentPage - delta);
      i <= Math.min(totalPages, currentPage + delta);
      i++
    ) {
      range.push(i);
    }

    if (range[0] > 1) {
      range.unshift(1);
      if (range[1] > 2) range.splice(1, 0, '...');
    }

    if (range[range.length - 1] < totalPages) {
      if (range[range.length - 1] < totalPages - 1) range.push('...');
      range.push(totalPages);
    }

    return range;
  };

  const renderPageButton = (pageNumber: PageNumber, index: number) => {
    if (pageNumber === '...') {
      return (
        <span
          key={`ellipsis-${index}`}
          className="px-3 py-1 rounded-md border cursor-default"
        >
          {pageNumber}
        </span>
      );
    }

    return (
      <button
        key={`page-${pageNumber}`}
        onClick={() => paginate(pageNumber)}
        className={`px-3 py-1 rounded-md border ${
          pageNumber === currentPage
            ? 'bg-green-500 text-white'
            : 'hover:bg-gray-50'
        }`}
      >
        {pageNumber}
      </button>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4 sm:p-6">
      <Card className="max-w-7xl mx-auto mb-8">
        <CardHeader>
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div className="flex items-center gap-3">
              <Phone className="w-8 h-8 text-[#48B527]" />
              <div>
                <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Detail's Calls of Users</h1>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center gap-4">
              <div className="flex items-center gap-2">
                <label className="text-sm font-medium text-gray-700">Show:</label>
                <select
                  value={itemsPerPage}
                  onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                  className="rounded border-gray-300 text-sm w-full sm:w-auto"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                </select>
                <span className="text-sm text-gray-700">entries</span>
              </div>
              <div className="relative w-full sm:w-auto">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="pl-8 pr-4 py-2 border rounded-lg w-full"
                />
                <Search className="w-4 h-4 absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
          </div>
        </CardHeader>
      </Card>

      <Card className="max-w-7xl mx-auto">
      <CardContent>
  <div className="overflow-x-auto hidden sm:block">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {['user', 'dateAndTime', 'source', 'destination', 'duration', 'direction'].map((key) => (
            <th
              key={key}
              onClick={() => handleSort(key as keyof DetailItem)}
              className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
            >
              <div className="flex items-center gap-1">
                {key}
                <ArrowUpDown className="w-4 h-4" />
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {currentItems.map((item, index) => (
          <tr key={index} className="hover:bg-gray-50">
            <td className="px-4 py-3 whitespace-nowrap">{item.user}</td>
            <td className="px-4 py-3 whitespace-nowrap">{new Date(item.dateAndTime).toLocaleString()}</td>
            <td className="px-4 py-3 whitespace-nowrap">{item.source}</td>
            <td className="px-4 py-3 whitespace-nowrap">{item.destination}</td>
            <td className="px-4 py-3 whitespace-nowrap">{item.duration}</td>
            <td className="px-4 py-3 whitespace-nowrap">{item.direction}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>

  {/* Mobile View - Cards */}
  <div className="block sm:hidden space-y-4">
    {currentItems.map((item, index) => (
      <div key={index} className="bg-white p-4 rounded-lg shadow-md">
        <div className="flex items-center gap-3 border-b pb-2 mb-2">
          <Phone className="w-5 h-5 text-green-500" />
          <h2 className="text-lg font-bold">{item.user}</h2>
        </div>
        <p className="text-sm text-gray-500"><strong>Date:</strong> {new Date(item.dateAndTime).toLocaleString()}</p>
        <p className="text-sm text-gray-500"><strong>Source:</strong> {item.source}</p>
        <p className="text-sm text-gray-500"><strong>Destination:</strong> {item.destination}</p>
        <p className="text-sm text-gray-500"><strong>Duration:</strong> {item.duration}</p>
        <p className="text-sm text-gray-500"><strong>Direction:</strong> {item.direction}</p>
      </div>
    ))}
  </div>

  {/* Pagination */}
  <div className="flex flex-col sm:flex-row items-center justify-between mt-4 px-2 sm:px-4">
    <div className="text-sm text-gray-700 mb-2 sm:mb-0">
      Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length} entries
    </div>
    
    <div className="flex items-center gap-2">
      <button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
        className="p-2 rounded-md border hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <ChevronLeft className="w-4 h-4" />
      </button>
      
      {getPageNumbers().map((number, index) => renderPageButton(number, index))}
      
      <button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="p-2 rounded-md border hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <ChevronRight className="w-4 h-4" />
      </button>
    </div>
  </div>
</CardContent>

      </Card>
    </div>
  );
};

export default DetailSummary;
